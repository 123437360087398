.bt-reply {
    color: blue;

    &:hover {
      color: blue;
    }
}

.button-icon-file {
  padding-top: 5px;
  &__count {
    color: #203040;
    font-family: "GT Walsheim Pro";
    font-size: 15px;
    line-height: 15px;
    text-transform: lowercase;
    margin-bottom: 5px;
  }

  &__upload {
    color: #20a8d8;
    font-family: "GT Walsheim Pro - Medium";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: #20a8d8;
  }
}

.icon-reply {
  padding: 5px 0;
  &__count {
    margin-bottom: 5px;
    color: #203040;
    font-family: "GT Walsheim Pro";
    font-size: 15px;
    line-height: 15px;

    &__number {
      margin-right: 5px;
    }

    &__mark {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 5px;

      &--close {
        border-top: 5px solid transparent;
        border-left: 7px solid #00a0e9;
        border-bottom: 5px solid transparent;
      }

      &--open {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #97abbe;
      }
    }
  }

  &__link {
    color: #20a8d8;
    font-family: "GT Walsheim Pro - Medium";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: #20a8d8;
  }
}

.last-update {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  color: #203040;
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  line-height: 15px;

  &__link {
    margin-top: 10px;
    color: #20a8d8;
    font-family: "GT Walsheim Pro - Medium";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: #20a8d8;
  }
}

.user-info {
  margin: 10px 0;
  &__name {
    color: #20a8d8;
    font-family: "GT Walsheim Pro - Medium";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 14px;
    margin-right: 5px;
  }

  &__company {
    color: #657380;
    font-family: "GT Walsheim Pro";
    font-size: 13px;
    letter-spacing: -0.1px;
    line-height: 14px;
    margin-right: 5px;
  }

  &__time {
    color: #8495a6;
    font-family: "GT Walsheim Pro";
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 11px;
    text-transform: uppercase;
  }
}

.QA-placeholder {
  color: #a0adba;
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  line-height: 5px;
}

.cell-format {
  position: relative;

  &__copy-icon {
    position: absolute;
    bottom: 1px;
    right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0;
    border-width: 0 0 34px 34px;
    border-color: transparent transparent transparent transparent;
    transition: border-color 0.5s;

    img {
      position: absolute;
      width: 0px;
      top: 17px;
      left: -16px;
      transition: width 0.3s;
    }

    &:hover {
      border-width: 0 0 34px 34px;
      border-color: transparent transparent #20a8d8 transparent;
      img {
        width: 14px;
      }
    }
  }
}
