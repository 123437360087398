@font-face {
  font-family: "GT Walsheim Pro";
  src: url("assets/fonts/gtwalsheimpro.ttf");
}

@font-face {
  font-family: "GT Walsheim Pro - Medium";
  src: url("assets/fonts/gtwalsheimpro-medium.ttf");
}

@font-face {
  font-family: "GT Walsheim Pro - Bold";
  src: url("assets/fonts/gtwalsheimpro-bold.ttf");
}

body {
  font-family: "GT Walsheim Pro";
}

.hcl-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;

  &>input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid rgba(32, 168, 216, 0.5);
    box-sizing: content-box;
  }

  &:hover input~&__checkmark {
    background-color: #fff;
  }

  &:hover input:checked~&__checkmark {
    background-color: #20a8d8;
    ;
  }

  input:checked~&__checkmark {
    background-color: #20a8d8;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~&__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    top: 7px;
    left: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
  }
}

.hcl-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  &:hover input~.checkmark {
    background-color: #ccc;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~&__checkmark {
    background-color: #00a0e9;
  }

  input:checked~&__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.hcl-checkbox-round {
  position: relative;

  &>label {
    background-color: #4a90e233;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;

    &:after {
      border: 2px solid #00a0e9;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 7px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  &>input[type="checkbox"] {
    visibility: hidden;

    &:checked+label {
      background-color: #fff;
      border-color: #fff;

      &:after {
        opacity: 1;
      }
    }
  }
}

.hcl-plus-icon {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background-color: rgba(53, 97, 172, 0.2);
  font-size: 26px;
  color: var(--hight-blue);
}

.hcl-index-number {
  color: #2e5596;
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  padding-top: 5px;
}

.hcl-table {
  border-radius: 8px;

  &__project-name {
    color: #203040;
    font-family: "GT Walsheim Pro";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 20px;
  }

  &__actions-list {
    &>div {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__setting {
      height: 32px;
      cursor: pointer;
    }
  }

  thead {
    th {
      border-bottom: none;
    }

    tr {
      background-color: rgba(32, 168, 216, 0.1);
      color: #203040;
      font-family: "GT Walsheim Pro";
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.4px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      color: #203040;
      font-family: "GT Walsheim Pro";
      font-size: 16px;
      line-height: 16px;
      background-color: #fff !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.01) !important;
      }

      &.hcl-table__submit-row {
        &:hover {
          background-color: #fff !important;
        }
      }
    }
  }

  &__submit-row {
    &__message-text {
      padding-top: 18px;
      padding-left: 1rem;
    }

    &__icon-wrapper {
      &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &__form-wrapper {
      padding: 0 !important;

      .form-group {
        margin: 0px;

        input {
          border: none;
          padding: 0;
          padding-left: 1rem;
          height: 56px;
          color: #203040;
          font-family: "GT Walsheim Pro";
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.4px;
          line-height: 20px;

          &::placeholder {
            color: #a0adba;
            font-family: "GT Walsheim Pro";
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .hcl-btn-view,
  .hcl-btn-primary {
    display: inline-block;
  }
}

.hcl-btn-view {
  color: #20a8d8;
  font-family: "GT Walsheim Pro";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 12px;
  border-bottom: 1px solid #20a8d8;
  text-transform: uppercase;
  cursor: pointer;
}

.hcl-btn-primary {
  color: #20a8d8;
  padding: 8px;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 16px;
  border-radius: 6px;
  background-color: rgba(32, 168, 216, 0.2);
  cursor: pointer;
}

.hcl-number-box {
  display: flex;
  align-items: center;

  &__box {
    height: 26px;
    width: 26px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(46, 85, 150, 0.2);
    color: #2e5596;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
  }

  &__text {
    color: #203040;
    font-family: "GT Walsheim Pro";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 20px;
  }
}

.hcl-search-box {
  position: relative;

  img {
    height: 20px;
    position: absolute;
    left: 8px;
    top: 10px;
  }

  input {
    width: 240px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding-left: 40px !important;
  }
}

.custom-select-cell {
  .select-item {
    white-space: normal;
  }
}

.field-table-custom {
  margin: 0;
  cursor: pointer;
  position: relative;

  .fa-chevron-down,
  .fa-chevron-up {
    border: 1px solid;
    border-radius: 50%;
    padding: 12px;
    position: relative;
    font-size: 16px;

    &:before {
      position: absolute;
      left: 4px;
      top: 7px;
    }

    &.empty {
      &:before {
        top: 2px;
      }
    }
  }

  .fa-chevron-up {
    &:before {
      top: 2px;
    }
  }

  .fa-trash-o {
    font-size: 16px;
    position: relative;
    border: 1px solid;
    padding: 12px;
    border-radius: 50%;

    &:before {
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }

  span {
    position: absolute;
    top: 0px;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    width: 100%;

    &.count-file-text {
      top: 5px;
      font-weight: normal;
      font-size: 12px;
    }
  }
}

body {
  background-color: #F8F8F8;
}

.table {
  &__limit-width {
    display: block;
    word-break: break-all;
  }
}

.table-sort {
  th {
    &.sort {
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        display: none;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid #00a0e9;
        position: absolute;
        right: 12px;
        top: calc(50% - 5px);
      }

      &::before {
        content: "";
        display: none;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #00a0e9;
        position: absolute;
        right: 12px;
        top: calc(50% - 5px);
      }
    }

    &.sort-desc {
      border-bottom: 2px solid #00a0e9;

      &::after {
        display: block;
      }
    }

    &.sort-asc {
      border-bottom: 2px solid #00a0e9;

      &::before {
        display: block;
      }
    }
  }
}

.field {
  position: relative;
  $root: &;

  &__input {
    color: #566573;
    font-weight: 500;

    &:focus {
      color: #566573;
      font-weight: 500;
    }

    &--invalid {
      border-color: #f86c6b;

      &:focus {
        border-color: #f86c6b;
        box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, .25);
      }
    }

    &::placeholder {
      color: #5c6873;
      font-weight: 400;
      opacity: .9;
    }
  }

  &__error-box {
    position: absolute;
    right: 15px;
    top: 9px;
    text-align: right;
    z-index: 3;
  }

  &__error-icon {
    color: #f86c6b;
    font-size: 18px;
    cursor: pointer;
    margin-right: 5px;

    &:hover~#{$root}__error-alert {
      display: block;
    }
  }

  &__error-alert {
    display: none;
    position: relative;
    font-size: 12px;
    padding: 5px 10px;
    text-align: left;
    margin-bottom: 5px;
    color: #813838;
    background-color: #fee2e1;
    border-color: #fdd6d6;
    border: 1px solid transparent;
    border-radius: 5px;
  }
}

.assignee {
  white-space: normal;
  padding-top: 5px;

  &__company {
    font-style: italic;
    color: #5e93c5;
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: -10px;
  background: rgba(0, 0, 0, .4);
  z-index: 9999;

  &__wapper {
    background: white;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    width: 100%;
    height: 100px;
    background-color: #e85e6c;
    align-items: center;

    &__icon {
      color: #fff;
      width: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
    text-align: center;
    margin: 15px;
  }
}