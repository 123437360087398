// Here you can add other styles
.modal {
  .close {
    outline: none;
  }
}

.nav-pills .nav-item .nav-link {
    line-height: 24px;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    color: #555;
    transition: all .3s;
    padding: 10px 15px;
    text-align: center;
    border-radius: 4px;
}


.nav-pills:not(.flex-column) .nav-item+.nav-item:not(:first-child) {
    margin-left: 5px;
}

.nav-pills .nav-item .nav-link.active {
    color: #fff;
    background-color: #007bff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(0, 123, 255, .6);
}


.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
    background-color: #00bcd4;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(0, 188, 212, .6);
    color: #fff;
}


.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
    background-color: #4caf50;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(76, 175, 80, .6);
    color: #fff;
}


.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
    background-color: #ff9800;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(255, 152, 0, .6);
    color: #fff;
}


.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
    background-color: #f44336;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(244, 67, 54, .6);
    color: #fff;
}

.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
    background-color: #e91e63;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgba(233, 30, 99, .6);
    color: #fff;
}

.nav-pills .nav-item .nav-link:not(.active):hover {
    background-color: rgba(200, 200, 200, .2);
}

.nav-pills .nav-item i {
    display: block;
    font-size: 3rem;
}


.nav-pills.flex-column .nav-item+.nav-item {
    margin-top: 5px;
}

.tab-pane {
    font-weight: 300;
}

.tab-space {
    padding: 20px 0 50px;
    font-weight: 300;
}

.nav-company-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-direction: row;
    font-size: 2.5rem;
    font-weight: bold;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #e8e8e8;
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    /* transition: 0.3s; */
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.btn-cell {
  padding: 0;

  &:focus {
    box-shadow: none;
  }
}

// logo
.navbar-brand img {
    max-width: 100%;
    max-height: 100%;
}

.diable-mouse {
    pointer-events: none;
}


.tabulator.disable-row-move {
    .tabulator-row {

        .icon-menu {
            display: none !important;
        }
    }
}

.form-control:focus {
  border: 1px solid #e4e7ea;
  box-shadow: none;
}

.main .container-fluid {
    padding: 0 5px;
}

.modal-title {
    word-break: break-all;
}