.tags-input {
  display: block;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0;
  input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0 0;
}

.tag {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 10px;
  font-size: 14px;
  list-style: none;
  margin: 0 8px 8px 0;
  border-radius: 3px;
  background: #03a9f4;
  position: relative;
  .tag-title {
    margin-top: 3px;
  }
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 15px;
    text-align: center;
    font-size: 15px;
    margin-left: 8px;
    color: #03a9f4;
    border: 1px solid #03a9f4;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -3px;
  }
  // &:hover {
  //   .tag-close-icon {
  //     right: 2px;
  //   }
  // }
}

.tag-input {
  list-style: none;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}
